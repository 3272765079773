import request from '@/utils/request'

const customerApi = {
  topic_result_list: '/topic/result/',
  export_pdf: '/pdf/',
  customer_update: '/customer/',
  customer_delete: '/customer/',
  customer_manager_change: '/manager/change/',
  customer_notification_create: '/customer/notification/',
  customer_notification_list: '/customer/notification/',
  customer_notification_delete: '/customer/notification/',
  customer_change_password_update: '/customer/',
  customer_reset_password_update: '/customer/',
  customer_permission_list: '/customer/permission/'
}

/**
 * 列表
 */
export function topic_result_list (parameter) {
  return request({
    url: customerApi.topic_result_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function export_pdf (parameter) {
  return request({
    url: customerApi.export_pdf,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
export function customer_notification_list (parameter) {
  return request({
    url: customerApi.customer_notification_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function customer_notification_create (parameter) {
  return request({
    url: customerApi.customer_notification_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 添加
 */
export function customer_create (parameter) {
  return request({
    url: customerApi.customer_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function customer_update (parameter, customer_id) {
  return request({
    url: customerApi.customer_update + customer_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改用户密码
 */
export function customer_change_password_update (parameter, customer_id) {
  return request({
    url: customerApi.customer_change_password_update + customer_id + '/change_password/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 重置用户密码
 */
export function customer_reset_password_update (parameter, customer_id) {
  return request({
    url: customerApi.customer_reset_password_update + customer_id + '/reset_password/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function customer_notification_delete (un_id) {
  return request({
    url: customerApi.customer_notification_delete + un_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除
 */
export function customer_delete (customer_id) {
  return request({
    url: customerApi.customer_delete + customer_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加
 */
export function customer_manager_change (parameter) {
  return request({
    url: customerApi.customer_manager_change,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
