<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名字">
              <a-input v-model="queryParam.name" placeholder="名字" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.phone" placeholder="手机号" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="img" slot-scope="img">
        <a-avatar
          v-if="img"
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="pdf(record)">生成PDF</a>
          <a-divider type="vertical" />
        </template>
      </span>
    </s-table>
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'

import {
  topic_result_list,
  export_pdf
} from '@/api/customer'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      previewvisible: false,
      previewurl: '',
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名字',
          dataIndex: 'name'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          ellipsis: true
        },
        {
          title: '身份证号',
          dataIndex: 'id_card'
        },
        {
          title: '成绩',
          dataIndex: 'score'
        },
        {
          title: '等级',
          dataIndex: 'level'
        },
        {
          title: '签名',
          dataIndex: 'sign',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '生成时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          width: '250',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return topic_result_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  methods: {
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    pdf (item) {
      console.log(item)
      export_pdf({ answer_id: item.id }).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', item.name + '-' + item.phone + '-' + item.level + '-' + `.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>
