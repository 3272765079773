<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['phone', {rules: [{required: true, message: '请输入'}]}]" />
          </a-form-item>
          <a-form-item label="名字" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['name', {rules: [{required: false, message: '请输入'}, {min:1, max:5, message: '输入不符合长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="身份证" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['id_card', {rules: [{required: true, message: '请输入'}]}]" @change="onChange" />
          </a-form-item>
          <a-form-item label="出生年月" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['birth', {rules: [{required: false, message: '请输入'}]}]" disabled />
          </a-form-item>
          <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
              disabled
              v-decorator="[
                'gender',
                {
                  rules: [
                    { required: false },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :value="1">
                女
              </a-select-option>
              <a-select-option :value="2">
                男
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="单位" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['workplace', {rules: [{required: false, message: '请输入'}]}]" />
          </a-form-item>
          <a-form-item label="所属项目" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['project', {rules: [{required: false, message: '请输入'}]}]" />
          </a-form-item>
          <!--          <a-form-item label="管理员" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
              v-decorator="[
                'manager_id',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              option-label-prop="tt"
              show-search
              allow-clear
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              placeholder="请输入用户名"
              :default-active-first-option="false"
              @search="handleUserSearch"
              @change="handleUserChange"
            >
              <a-select-option v-for="d in dataSource_Users" :key="d.id" :tt="d.external_name">
                {{ d.external_name }}
              </a-select-option>
            </a-select>
          </a-form-item>-->
          <a-form-item label="地区" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['area', {rules: [{required: false, message: '请输入'}]}]" />
          </a-form-item>
          <a-form-item label="客户编码" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-decorator="['customer_code', {rules: [{required: false, message: '请输入'},{min:1, max:35, message: '输入不符合长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="是否启用" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-switch v-decorator="['used', {initialValue: true, valuePropName: 'checked'}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'

const { customer_update } = require('@/api/customer')
const { user_list } = require('@/api/user')
const fields = ['name', 'id_card', 'birth', 'gender', 'workplace', 'project', 'customer_code', 'area', 'phone', 'used']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 6, offset: 10 }
        }
      },
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      form: this.$form.createForm(this),
      id: 0,
      dataSource_Users: []
    }
  },
  created () {
    if (this.record.manager_id && this.record.manager_id !== -1 && this.record.manager_id !== 0) {
      user_list({ id: this.record.manager_id }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_Users = result
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    onChange (e) {
      const { value } = e.target
      if (value && value.length === 18) {
        console.log(value)
        this.form.setFieldsValue({
          birth: this.IdCard(value, 1)
        })
        this.form.setFieldsValue({
          gender: this.IdCard(value, 2)
        })
      }
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        customer_update(values, this.id)
          .then((res) => {
            // this.$notification.success({
            //   message: '修改成功',
            //   description: `修改成功`
            // })
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        if (data.manager_id === -1 || data.manager_id === 0) {
          data.manager_id = undefined
        }
        const formData = pick(data, fields)
        console.log('formData', formData)
        this.id = data.id
        form.setFieldsValue(formData)
      })
    },
    IdCard (IdCard, type) {
      if (type === 1) {
        // 获取出生日期
        const birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        // 获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return 2
        } else {
          return 1
        }
      }
      if (type === 3) {
        // 获取年龄
        const ageDate = new Date()
        const month = ageDate.getMonth() + 1
        const day = ageDate.getDate()
        let age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    handleUserSearch (value) {
      user_list({ external_name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_Users = !value ? [] : result
      })
    },
    handleUserChange (value) {
      if (value) {
        console.log(value)
      }
    }
  }
}
</script>
